/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import Seo from "../components/seo"
import FocusLock from "react-focus-lock";
import Bars from "./bars";
import Menu from "./menu";
import Header from "./header";
import Footer from "./footer";
import ScrollToTop from "./scroll-top";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBehance, faBehanceSquare, faDrupal, faInstagram, faInstagramSquare, faLinkedin, faLinkedinIn, faSpotify, faTwitter, faTwitterSquare } from "@fortawesome/free-brands-svg-icons"
import { faAngleDoubleRight, faArrowAltCircleUp, faBriefcase, faChevronRight, faChevronLeft, faEnvelope, faEnvelopeSquare, faGlobe, faGraduationCap, faLink, faThumbsUp, faTimes } from "@fortawesome/free-solid-svg-icons"
import { faArrowAltCircleRight, faCompass, faTimesCircle } from "@fortawesome/free-regular-svg-icons"
import "../app.scss"

library.add(faAngleDoubleRight, faArrowAltCircleRight, faArrowAltCircleUp, faBehance, faBehanceSquare, faBriefcase, faChevronRight, faChevronLeft, faCompass, faDrupal, faEnvelope, faEnvelopeSquare, faGlobe, faGraduationCap, faInstagram, faInstagramSquare, faLink, faLinkedin, faLinkedinIn, faSpotify, faTwitter, faTwitterSquare, faThumbsUp, faTimes, faTimesCircle)

const Layout = ({ pageTitle, titleClass, children }) => {
  const data = useStaticQuery(graphql`
    query SiteMetadataQuery {
      site {
        siteMetadata {
          title
          subtitle
        }
      }
    }
  `)

  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "responsive-menu";

  // const handleMediaQueryChange = (matches) => {
  //   if (!matches) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // }

  const isDesktop = useMediaQuery({ 
    query: "(min-width: 768px)"
  });

  let mainTitleClass = "main__page-title";
  if (titleClass !== "") {
    mainTitleClass = `main__page-title ${titleClass}`;
  }

  // useOnClickOutside(node, () => {
  //   if (isMobile) {
  //     setOpen(false)
  //   }
  // });

  /**
   * DON'T NEED the useOnClickOutside, because the mobile menu is set to fill the screen,
   * (i.e. there is no "outside").
   */

  return (
    <>
    {/* <GlobalStyles /> */}
    <div className="layout">
      <Seo title="chris.welchhans.com" />
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Lora:wght@700&family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600&family=Poppins:wght@300;600;700;800&display=swap" rel="stylesheet" />
      </Helmet>
      <div ref={node} className="navigation-wrapper">
        {isDesktop &&
          <Menu open={isDesktop} id={menuId} />
        }
        {!isDesktop &&
          <FocusLock disabled={!open}>
            <Bars open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} />
          </FocusLock>
        }
      </div>
      <Header 
        siteTitle={data.site.siteMetadata?.title || `Title`} 
        siteSubtitle={data.site.siteMetadata.subtitle}
      />
      <main>
        <div className="container px-4 pb-5">
          <h1 className={mainTitleClass}>{pageTitle}</h1>
          <div className="margin-fix" aria-hidden="true"></div>
          {children}
        </div>
      </main>
      <Footer copyright={data.site.siteMetadata.copyright} />
      <ScrollToTop />
    </div>
    </>
  )
}

Layout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  titleClass: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
