import React from "react";
import { bool, func } from 'prop-types';

const Bars = ({ open, setOpen }) => {
    const isExpanded = open ? true : false;

    function handleClickEvent() {
        setOpen(!open);
        const body = document.body;
        if (open) {
            body.classList.remove("nav-open");

            const scrollY = document.body.style.top;
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);

            body.removeAttribute("class");
            body.removeAttribute("style");
        } else {
            body.classList.add("nav-open");
            body.style.top = `-${window.scrollY}px`;
        }
    }
    
    return (
        <button aria-label="Toggle menu" aria-expanded={isExpanded} className={`menu-nav__button ${open ? "close" : "open"}`} onClick={handleClickEvent}>
            <span />
            <span />
            <span />
        </button>
    )
};

Bars.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};

export default Bars;