import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle, siteSubtitle }) => {
  return (
    <header>
      <div
        className="container px-4"
        style={{
          margin: `0 auto`
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: `none`,
          }}
          className="row"
        >
          <div className="col-12 col-lg-auto site-logo px-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 509.792 273.9">
              <path d="M230.105 0c-1.3 0-2.199.6-2.199 1.9l-58.7 269.1c-.3 1.3.6 2.5 1.9 2.9h38.699c1.3 0 2.2-.6 2.2-1.9l58.7-268.8c.3-1.3-.6-2.501-1.9-2.901h-38.7zm142.528 65.2c-1 0-2.2.6-2.2 1.9l-24.4 107.1-22.199-107c-.3-1-1.301-1.901-2.201-1.901h-41.6c-1.3 0-2.5 1.001-2.5 2.201v.6l38.4 173c.3 1 1.3 1.9 2.2 1.9h54.9c1.3 0 2.201-.6 2.201-1.9l19.7-88.2 19.699 88.2c.3 1 1.301 1.9 2.201 1.9H470.434c1.3 0 2.199-.6 2.199-1.9l37.101-173c.3-1.3-.6-2.5-1.9-2.9h-41.9c-1.3 0-2.2.6-2.2 1.9l-21.3 106.3-23.2-106.3c-.3-1-1.3-1.9-2.2-1.9h-22.2zm-271.15 1.5c-13.374 0-26.223 2.016-38.547 6.05-12.325 3.81-23.209 9.413-32.649 16.809-9.178 7.395-16.519 16.583-22.025 27.564C2.755 127.88 0 140.205 0 154.1c0 13.894 2.492 26.331 7.475 37.312 4.982 10.757 11.8 19.833 20.453 27.229 8.653 7.395 18.749 13.11 30.287 17.144 11.8 3.81 24.518 5.715 38.154 5.715 10.751 0 21.765-1.458 33.041-4.371 11.276-3.138 21.765-8.292 31.467-15.463l-22.815-32.27c-10.064 6.4-18.755 12.548-34.68 12.973-27.059.723-48.899-21.893-48.675-48.27.195-22.955 15.727-43.373 41.03-47.023 15.304-2.207 27.536 2.828 35.64 9.375l27.14-31.935c-7.342-5.155-15.733-9.412-25.173-12.774-9.178-3.361-19.8-5.043-31.862-5.043z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 509.792 273.9">
              <path d="M230.105 0c-1.3 0-2.199.6-2.199 1.9l-58.7 269.1c-.3 1.3.6 2.5 1.9 2.9h38.699c1.3 0 2.2-.6 2.2-1.9l58.7-268.8c.3-1.3-.6-2.501-1.9-2.901h-38.7zm142.528 65.2c-1 0-2.2.6-2.2 1.9l-24.4 107.1-22.199-107c-.3-1-1.301-1.901-2.201-1.901h-41.6c-1.3 0-2.5 1.001-2.5 2.201v.6l38.4 173c.3 1 1.3 1.9 2.2 1.9h54.9c1.3 0 2.201-.6 2.201-1.9l19.7-88.2 19.699 88.2c.3 1 1.301 1.9 2.201 1.9H470.434c1.3 0 2.199-.6 2.199-1.9l37.101-173c.3-1.3-.6-2.5-1.9-2.9h-41.9c-1.3 0-2.2.6-2.2 1.9l-21.3 106.3-23.2-106.3c-.3-1-1.3-1.9-2.2-1.9h-22.2zm-271.15 1.5c-13.374 0-26.223 2.016-38.547 6.05-12.325 3.81-23.209 9.413-32.649 16.809-9.178 7.395-16.519 16.583-22.025 27.564C2.755 127.88 0 140.205 0 154.1c0 13.894 2.492 26.331 7.475 37.312 4.982 10.757 11.8 19.833 20.453 27.229 8.653 7.395 18.749 13.11 30.287 17.144 11.8 3.81 24.518 5.715 38.154 5.715 10.751 0 21.765-1.458 33.041-4.371 11.276-3.138 21.765-8.292 31.467-15.463l-22.815-32.27c-10.064 6.4-18.755 12.548-34.68 12.973-27.059.723-48.899-21.893-48.675-48.27.195-22.955 15.727-43.373 41.03-47.023 15.304-2.207 27.536 2.828 35.64 9.375l27.14-31.935c-7.342-5.155-15.733-9.412-25.173-12.774-9.178-3.361-19.8-5.043-31.862-5.043z"/>
            </svg>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 273.868">
              <path d="M311.318 0c-1.27 0-1.954.353-2.223 1.585l-58.71 269.426c-.317 1.27.637 2.54 1.906 2.857h38.715c1.27 0 1.885-.973 2.223-2.22l58.71-268.791c.317-1.27-.637-2.54-1.906-2.857h-.634zM2.54 65.372c-1.27 0-2.54.953-2.54 2.222v.635L38.4 241.18c.318.952 1.269 1.905 2.22 1.905h54.902c1.27 0 2.22-.636 2.22-1.905l19.678-88.22 19.674 88.22c.318.952 1.27 1.905 2.223 1.905h53.63c1.27 0 2.223-.636 2.223-1.905l37.126-172.952c.318-1.27-.633-2.54-1.902-2.857h-41.889c-1.27 0-2.223.636-2.223 1.905l-21.263 106.31-23.165-106.31c-.318-.952-1.268-1.905-2.22-1.905H95.205c-.952 0-2.222.636-2.222 1.905L68.549 174.221 46.334 67.277c-.317-.952-1.27-1.905-2.222-1.905z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 273.868">
              <path d="M311.318 0c-1.27 0-1.954.353-2.223 1.585l-58.71 269.426c-.317 1.27.637 2.54 1.906 2.857h38.715c1.27 0 1.885-.973 2.223-2.22l58.71-268.791c.317-1.27-.637-2.54-1.906-2.857h-.634zM2.54 65.372c-1.27 0-2.54.953-2.54 2.222v.635L38.4 241.18c.318.952 1.269 1.905 2.22 1.905h54.902c1.27 0 2.22-.636 2.22-1.905l19.678-88.22 19.674 88.22c.318.952 1.27 1.905 2.223 1.905h53.63c1.27 0 2.223-.636 2.223-1.905l37.126-172.952c.318-1.27-.633-2.54-1.902-2.857h-41.889c-1.27 0-2.223.636-2.223 1.905l-21.263 106.31-23.165-106.31c-.318-.952-1.268-1.905-2.22-1.905H95.205c-.952 0-2.222.636-2.222 1.905L68.549 174.221 46.334 67.277c-.317-.952-1.27-1.905-2.222-1.905z"/>
            </svg> */}
          </div>
          <div className="col-12 col-lg-auto px-0">
            <p className="site-title">{siteTitle}</p>
            <p className="site-subtitle">{siteSubtitle}</p>
          </div>
        </Link>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteSubtitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteSubtitle: ``,
}

export default Header
