import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class ScrollToTop extends React.Component {
    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.state = {
            is_visible: false
        };
    }

    componentDidMount() {
        const scrollToComponent = this;
        document.addEventListener("scroll", function(e) {
            scrollToComponent.toggleVisible();
        });
    }

    toggleVisible() {
        if (window.pageYOffset > 200) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        const { is_visible } = this.state;
        return (
            <div className={`scroll-to-top ${(is_visible) ? "show" : "hide"}`}>
                {is_visible && (
                    <button aria-label="Scroll to top" className="scroll-to-top__button" onClick={this.scrollToTop}>
                        <FontAwesomeIcon className="fa-icon" icon={["fas", "arrow-alt-circle-up"]} />
                    </button>
                )}
            </div>
        )
    }
}

export default ScrollToTop;