import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DrupalSVG from "../images/Drupal_EL_white_RGB.svg"
import GatsbySVG from "../images/Gatsby_Monogram_White.svg"

const Footer = () => {
  return (
    <footer>
        <div
          className="container"
        >
          <div className="row">
            <div className="footer__copyright col-12 col-md-6">
              &copy;{new Date().getFullYear()} Chris Welchhans
              <p className="footer__disclaimer">All opinions expressed on this site are my own.</p>
              <section className="footer__credit">
                <p>This site was built with <a href="https://drupal.org" title="Drupal - Open-source CMS" target="drupal">Drupal</a> and <a href="https://www.gatsbyjs.com/" title="Gatsby - The fastest frontend framework for headless CMS's" target="gatsby">Gatsby</a>.</p>
                <div>
                  <div className="footer__credit__icon">
                    <a href="https://drupal.org" title="Drupal - Open-source CMS" target="drupal">
                      <img src={DrupalSVG} alt="Drupal white logo" />
                    </a>
                  </div>
                  <div className="footer__credit__icon">
                    <a href="https://www.gatsbyjs.com/" title="Gatsby - The fastest frontend framework for headless CMS's" target="gatsby">
                      <img src={GatsbySVG} alt="Gatsby white logo" />
                    </a>
                  </div>
                </div>
              </section>
            </div>
            <div className="footer__links col-12 col-md-6">
              <a href="https://www.linkedin.com/in/chriswelchhans" target="linked-in">
                <FontAwesomeIcon className="fa-icon" icon={["fab", "linkedin-in"]} />
              </a>
              <a href="https://www.instagram.com/c.welchhans/" target="instagram">
                <FontAwesomeIcon className="fa-icon" icon={["fab", "instagram"]} />
              </a>
              <a href="https://open.spotify.com/user/cwelchhans?si=868d62feb61d4fa9" target="spotify">
                <FontAwesomeIcon className="fa-icon" icon={["fab", "spotify"]} />
              </a>
              <a href="https://twitter.com/ChrisWelchhans" target="twitter">
                <FontAwesomeIcon className="fa-icon" icon={["fab", "twitter"]} />
              </a>
              <a href="http://resume.welchhans.com" target="resume">
                <FontAwesomeIcon className="fa-icon" icon={["fas", "briefcase"]} />
              </a>
            </div>
          </div>
        </div>
    </footer>
  )
}

export default Footer
